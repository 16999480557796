import { JsonObject } from 'type-fest';

import { isString } from 'utils/is';

// NOTE: The API uses very inconsistent naming for error responses
export default ({
  responseBody = {},
  message,
}: {
  responseBody?: JsonObject;
  message?: string;
}): string | undefined => {
  if (isString(responseBody.userMessage)) {
    return responseBody.userMessage;
  }

  if (isString(responseBody.exceptionMessage)) {
    return responseBody.exceptionMessage;
  }

  if (isString(responseBody.systemMessage)) {
    return responseBody.systemMessage;
  }

  if (isString(responseBody.errorMessage)) {
    return responseBody.errorMessage;
  }

  if (isString(responseBody.message)) {
    return responseBody.message;
  }

  return message;
};
