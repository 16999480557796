import { Language } from 'utils/resolve-language';

const getAdyenLocale = (language?: Language): string => {
  switch (language) {
    case 'no':
      return 'no-NO';
    case 'fi':
      return 'fi-FI';
    case 'sv':
      return 'sv-SE';
    case 'da':
      return 'da_DK';
    default:
      return 'en-US';
  }
};

export default getAdyenLocale;
