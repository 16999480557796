export const login = {
  da: {
    title: 'Log ind',
    headerTitle: '',
    submitLabel: 'Log ind',
    labels: {
      errorSystemData: 'Noget gik galt. Det er os, ikke dig.',
      errorUserData:
        'Det er noget galt med den indtastede data. Har du husket at udfylde alle felter?',
      errorLogInTechnical: 'Noget gik galt da du prøvede at logge ind.',
      errorLogInUnknown:
        'Vi kunne ikke logge dig ind. Har du udfyldt alle felter korrekt?',
      errorLogInUnauthenticated:
        'Det er noget galt med den indtastede data. Har du husket at udfylde alle felter?',
      description:
        'Log ind med dit medlemsnummer eller den e-mail, du har tilmeldt dig.',
      forgotPassword: 'Glemt kodeord?',
      loggedInTemplate: 'You are already logged in as $name$',
      loginRequired: 'You need to be logged in to view this page',
      password: 'Adgangskode',
      passwordPlaceholder: 'Adgangskode',
      username: 'Medlemsnummer eller e-mail',
      usernamePlaceholder: 'Medlemsnummer eller e-mail',
    },
    modal: {
      close: 'Luk',
    },
  },
  fi: {
    title: 'Kirjaudu sisään',
    headerTitle: '',
    submitLabel: 'Kirjaudu sisään',
    labels: {
      errorSystemData: 'Jotain meni pieleen meidän päässämme.',
      errorUserData:
        'Syötetyissä tiedoissa näyttää olevan jotain pielessä. Muistitko täyttää kaikki kentät?',
      errorLogInTechnical:
        'Kirjautumisessa meni jotain pieleen. Jos ongelma jatkuu, olethan yhteydessä Asiakaspalveluun.',
      errorLogInUnknown:
        'Kirjautuminen epäonnistui. Täytithän kaikki kentät oikein?',
      errorLogInUnauthenticated:
        'Syötetyissä tiedoissa näyttää olevan jotain pielessä. Muistitko täyttää kaikki kentät?',
      description:
        'Kirjaudu sisään jäsennumerollasi tai sähköpostilla, jolla rekisteröidyit.',
      forgotPassword: 'Unohditko salasanasi?',
      loggedInTemplate: 'You are already logged in as $name$',
      loginRequired: 'You need to be logged in to view this page',
      password: 'Salasana',
      passwordPlaceholder: 'Salasana',
      username: 'Käyttäjätunnus',
      usernamePlaceholder: 'Käyttäjätunnus',
    },
    modal: {
      close: 'Sulje',
    },
  },
  no: {
    title: 'Innlogging',
    headerTitle: '',
    submitLabel: 'Logg inn',
    labels: {
      errorSystemData: 'Det skjedde noe feil. Det er oss altså, ikke deg!',
      errorUserData:
        'Det er noe feil med informasjonen du fylte ut. Husket du å fylle ut alt korrekt?',
      errorLogInTechnical:
        'Det skjedde noe feil når vi prøvde å logge deg inn. Prøv gjerne igjen, men hvis problemet fortsetter er det nok best du kontakter kundeservice.',
      errorLogInUnknown:
        'Vi klarte ikke å logge deg inn. Fylte du ut alt riktig?',
      errorLogInUnauthenticated:
        'Noe ser ut til å være galt. Har du fylt inn alle feltene?',
      description:
        'Logg inn med medlemsnummeret ditt, eller e-posten du har registrert deg med.',
      forgotPassword: 'Glemt passord?',
      loggedInTemplate: 'You are already logged in as $name$',
      loginRequired: 'You need to be logged in to view this page',
      password: 'Passord',
      passwordPlaceholder: 'Skriv passord her',
      username: 'Brukernavn / e-post',
      usernamePlaceholder: 'Skriv inn brukernavn eller e-post her',
    },
    modal: {
      close: 'Lukk',
    },
  },
  sv: {
    title: 'Logga in',
    headerTitle: '',
    submitLabel: 'Logga in',
    labels: {
      errorSystemData: 'Något blev fel. Hos oss, inte hos dig.',
      errorUserData:
        'Det verkar vara något fel med dina angivna uppgifter. Fyllde du i alla fält?',
      errorLogInTechnical:
        'Just nu har vi ett tillfälligt, fel vänligen försök senare, tack för ditt tålamod!',
      errorLogInUnknown:
        'Vi kunde inte logga in dig. Fyllde du i alla fält korrekt?',
      errorLogInUnauthenticated:
        'There seems to be something wrong with the entered data. Did you fill in all the fields correctly?',
      description:
        'Logga in med ditt medlemsnummer eller den e-postadress du registrerade dig med.',
      forgotPassword: 'Glömt ditt lösenord?',
      loggedInTemplate: 'You are already logged in as $name$',
      loginRequired: 'You need to be logged in to view this page',
      password: 'Fyll i ditt lösenord',
      passwordPlaceholder: 'Lösenord',
      username: 'Fyll i ditt användarnamn',
      usernamePlaceholder: 'Medlems-ID/mailadress',
    },
    modal: {
      close: 'Stäng',
    },
  },
};
