import type { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

const NodeWrapper: React.FC<
  React.PropsWithChildren<{
    nodeType: string;
  }>
> = ({ nodeType, children }) => (
  <div className={`rich-text__node-${nodeType}`} data-node-type={nodeType}>
    {children}
  </div>
);

const defaultRenderNodes: NonNullable<
  Parameters<typeof documentToReactComponents>[1]
>['renderNode'] = {
  [BLOCKS.PARAGRAPH]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="p" size={Text.sizes.basic}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_1]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="h1" size={Text.sizes.headline2}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_2]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="h2" size={Text.sizes.headline3}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_3]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="h3" size={Text.sizes.large}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.UL_LIST]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <ul>{children}</ul>
    </NodeWrapper>
  ),
  [BLOCKS.OL_LIST]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <ol>{children}</ol>
    </NodeWrapper>
  ),
};

export const renderOptions = {
  renderNode: defaultRenderNodes,
};
