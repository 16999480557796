import { JsonObject } from 'type-fest';

import { fromQueryString } from 'server/query-strings';

import extractServerError from './extract-server-error';

const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const parseResponse = async (response: Response): Promise<JsonObject> => {
  const text = await response.text();
  if (!text) {
    return {};
  }

  try {
    const json = JSON.parse(text);

    if (Array.isArray(json)) {
      return {};
    }

    if (typeof json === 'object') {
      return json;
    }

    return {};
  } catch {
    return {};
  }
};

class ApiError extends Error {
  responseStatus: number;
  responseBody: JsonObject;
  extra: {
    query?: ReturnType<typeof fromQueryString>;
    response: JsonObject;
  };
  constructor(message: string) {
    super(message);
  }
}

const handleNotOk = (response: Response, json: JsonObject) => {
  if (!response.ok) {
    const message = extractServerError({ responseBody: json });
    const error = new ApiError(message ? message : response.statusText);

    const [url, query] = response.url.split('?');

    error.name = `${response.status} on ${url}`;
    error.responseStatus = response.status;
    error.responseBody = json;
    error.extra = {
      query: query ? fromQueryString(query) : undefined,
      response: json,
    };

    throw error;
  }
};

const unpackPayload = <T extends JsonObject>(body: JsonObject): T => {
  if (body && body.payload) {
    return body.payload as T;
  }

  return body as T;
};

const request = async <T extends JsonObject>(
  url: string,
  options: RequestInit = {}
): Promise<T> => {
  const headers = Object.assign(
    {},
    defaultFetchOptions.headers,
    options.headers
  );

  const fetchOptions = Object.assign({}, defaultFetchOptions, options, {
    headers,
  });

  const response = await fetch(url, fetchOptions);
  const json = await parseResponse(response);

  handleNotOk(response, json);

  return unpackPayload<T>(json);
};

export const get = <T extends JsonObject>(
  endpoint: string,
  options: RequestInit = {}
) => request<T>(endpoint, options);
