import type { HTMLAttributes } from 'react';
import type React from 'react';

import { ObjectValues } from 'sats-ui-lib/react/types';

export const themes = {
  fullToWide: 'full-to-wide',
  medium: 'medium',
  narrow: 'narrow',
  veryNarrow: 'very-narrow',
  wide: 'wide',
} as const;

export interface ContentContainer extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  raw?: boolean;
  theme?: ObjectValues<typeof themes>;
}
