import React from 'react';

import Message from 'sats-ui-lib/react/message/message';
import Progressbar from 'sats-ui-lib/react/progress-bar';
import { labelPositions } from 'sats-ui-lib/react/progress-bar/progress-bar.types';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'shared-ui/components/content-container/content-container';
import { texts as shared } from 'shared-ui/texts';

import interpolate from 'helpers/interpolate';

import CheckoutSummary from '../checkout-summary/checkout-summary';
import Discountcode from '../discount-code/discount-code';
import Logo from '../logo/logo';

import { SplitLayout as Props } from './split-layout.props';

const SplitLayout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  campaignCode,
  checkoutSummary,
  children,
  corporateCode,
  language,
  logoHref,
  logoLabel,
  messages = [],
  progressBar,
  title,
}) => {
  const sharedTexts = shared[language];
  return (
    <div className="split-layout">
      <header className="split-layout__header">
        <div className="split-layout__header-content">
          <Logo
            href={logoHref}
            label={logoLabel}
            language={language}
            className="split-layout__header-content-logo"
          />
          <div className="split-layout__header-content-progress">
            <Progressbar
              value={progressBar.value}
              max={progressBar.max}
              variant={Progressbar.variants.cta}
              label={{
                text: interpolate(sharedTexts.step, {
                  value: progressBar.value.toString(),
                  max: progressBar.max.toString(),
                }),
              }}
              labelPosition={labelPositions.topLeft}
            />
          </div>
        </div>
      </header>
      <div className="split-layout__main">
        <div className="split-layout__left-outer">
          <ContentContainer theme={ContentContainer.themes.narrow}>
            <div className="split-layout__left-inner">
              {checkoutSummary ? (
                <div className="split-layout__summary">
                  <CheckoutSummary {...checkoutSummary} />
                </div>
              ) : null}
              <div className="split-layout__discounts">
                {campaignCode ? <Discountcode {...campaignCode} /> : null}
                {corporateCode ? <Discountcode {...corporateCode} /> : null}
              </div>
            </div>
          </ContentContainer>
        </div>
        <div className="split-layout__right-outer">
          <ContentContainer theme={ContentContainer.themes.narrow}>
            <div className="split-layout__right-inner">
              <Text
                className="split-layout__title"
                elementName="h1"
                size={'headline1'}
                theme={Text.themes.emphasis}
              >
                {title}
              </Text>
              {messages.length ? (
                <div className="split-layout__messages">
                  {messages.map(message => (
                    <Message key={message.text} {...message} />
                  ))}
                </div>
              ) : null}
              {children}
            </div>
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};

export default SplitLayout;
