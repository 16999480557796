import React from 'react';

import Message from 'sats-ui-lib/react/message/message';
import Progressbar from 'sats-ui-lib/react/progress-bar';
import { labelPositions } from 'sats-ui-lib/react/progress-bar/progress-bar.types';
import Text from 'sats-ui-lib/react/text';

import { texts as shared } from 'shared-ui/texts';

import interpolate from 'helpers/interpolate';

import CompletedSteps from '../completed-steps/completed-steps';
import Logo from '../logo/logo';

import { Layout as Props } from './layout.props';

const Layout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  completedSteps,
  language,
  logoHref,
  logoLabel,
  messages = [],
  step,
  title,
}) => {
  const sharedTexts = shared[language];

  return (
    <div className="layout">
      <div className="layout__header">
        <div className="layout__header-content">
          <Logo
            className="layout__header-content-logo"
            href={logoHref}
            label={logoLabel}
            language={language}
          />
          <div className="layout__header-content-progress">
            <Progressbar
              value={step.progressBar.value}
              max={step.progressBar.max}
              variant={Progressbar.variants.cta}
              label={{
                text: interpolate(sharedTexts.step, {
                  value: step.progressBar.value.toString(),
                  max: step.progressBar.max.toString(),
                }),
              }}
              labelPosition={labelPositions.topLeft}
            />
            <div className="layout__header-content-title">
              <Text theme={'headline'} italic={true} size={Text.sizes.large}>
                {step.title}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="layout__content">
        {completedSteps ? (
          <div className="layout__content-completed-steps">
            <CompletedSteps {...completedSteps} />
          </div>
        ) : null}
        <div className="layout__content-main">
          <Text
            className="layout__content-title"
            elementName="h1"
            size={'headline1'}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
          {messages.length ? (
            <div className="layout__content-messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
