type QueryInnerValue = string | number;
export type QueryValue = QueryInnerValue | QueryInnerValue[] | undefined;
export type QueryObject = Record<string, QueryValue>;
const isDefinedQueryValue = (
  value: QueryValue
): value is Exclude<QueryValue, undefined> => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'string') {
    return value.length > 0;
  }

  if (typeof value === 'boolean') {
    return true;
  }

  if (typeof value === 'undefined') {
    return false;
  }

  if (!isNaN(value)) {
    return true;
  }

  return false;
};

export const toQueryString = (queryObject: QueryObject) => {
  const url = new URL('https://example.com');

  Object.entries(queryObject).forEach(([key, value]) => {
    if (isDefinedQueryValue(value)) {
      if (Array.isArray(value)) {
        value.forEach(innerValue =>
          url.searchParams.append(key, String(innerValue))
        );
      } else {
        url.searchParams.append(key, String(value));
      }
    }
  });

  return `?${url.searchParams.toString()}`;
};

const maybeNumber = (string: string) =>
  isNaN(Number(string)) ? string : Number(string);
const exists = (thing: string | undefined): thing is string =>
  typeof thing !== 'undefined' && thing !== '';

const maybePair = (val: string) => {
  const p = val.split(',').filter(exists).map(maybeNumber);
  return p.length > 1 ? p : p[0];
};

export function fromQueryString(
  queryString: string,
  prefix = '?'
): QueryObject {
  if (!queryString || queryString === '?') {
    return {};
  }

  /* NOTE: Query object with lists of strings that need to be parsed further:
    "a=a,b&a=c&a=1" --> { a: ["a,b", "c", "1"] }
    */
  const partialParse = queryString
    .replace(prefix, '')
    .split('&')
    .map(keyAndValue => keyAndValue.split('='))
    .filter(isDefinedQueryValue)
    .reduce(
      (accumulator, [key, value]: [string, string]) => {
        if (!exists(value)) return accumulator;
        const oldValue = accumulator[key] || [];
        return { ...accumulator, [key]: [...oldValue, value] };
      },
      <Record<string, [string, ...string[]]>>{}
    );

  /* NOTE: Turns partial parse object into actual query object:
    { a: ["a,b", "c", "1" ] } --> { a: [["a", "b"], "c", 1 ] }
     */
  return Object.fromEntries(
    Object.entries(partialParse).map(([key, value]) => {
      if (value.length === 1) return [key, maybePair(value[0])];
      return [key, value.map(maybePair)];
    })
  );
}

export function replaceQueryParameters(
  url: string,
  query?: QueryObject
): string {
  if (!url || !query) return url;

  const [baseUrl, queryString] = url.split(/\?|#/) as [string, ...string[]];
  const newQueryString = toQueryString(
    Object.assign({}, queryString ? fromQueryString(queryString) : {}, query)
  );

  return `${baseUrl}${newQueryString.endsWith('?') ? newQueryString.slice(0, newQueryString.length - 1) : newQueryString}`;
}
