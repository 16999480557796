export const texts = {
  da: {
    step: 'Trin $value$ af $max$',
    back: 'Tilbage',
    change: 'Ændre',
    changeAddons: 'Ændre dine add-ons',
    changeClub: 'Ændre hovedcenter',
    changeMembertype: 'Ændre medlemstype',
    changeMembership: 'Ændre medlemskab',
    choose: 'Vælg',
    club: 'Hovedcenter',
    membership: 'Medlemskab',
    memberType: 'Medlemstype',
    generic: 'Noget gik galt. Prøv venligst igen senere',
    missingQueryParams: 'Noget gik galt, og vi var nødt til at gå tilbage.',
    expiredSession:
      'Din session udløb, og vi var nødt til at gå tilbage til, hvor vi kunne starte en ny session.',
    authenticationFailed:
      'Der ser ud til at være noget galt med de indtastede data. Har du udfyldt alle felterne korrekt?',
    checkoutSummary: {
      addons: 'Dine add-ons',
      appliedCampaign: 'Aktiv kampagne',
      homeClub: 'Stamcenter',
      memberType: 'Medlemstype',
      bindingPeriod: 'Binding',
      signUpFee: 'Oprettelsesgebyr',
      bindingPeriodTotalPrice: 'Samlet pris for bindingsperioden',
      price: 'Medlemskontingent',
      standardPrice: 'Normal pris efter kampagneperioden $standardPrice$',
    },
    paymentSummary: {
      signUpFee: 'Oprettelsesgebyr',
      firstPaymentPeriod: 'Første betalingsperiode',
      total: 'Totalt',
    },
    logout: 'Log ud',
    close: 'Luk',
    next: 'Næste',
    open: 'Åbn',
    campaignCode: 'Kampagnekode',
    campaignCodeButtonSubmit: 'Tilføj',
    campaignCodeButtonRemove: 'Slet kampagnekode',
    campaignCodePlaceholder: 'Har du en kampagnekode? Tilføj her',
    campaignCodeSuccessHeader: 'Tillykke!',
    campaignCodeSuccessBody: '$campaignCode$ er blevet tilføjet.',
    corporateCode: 'Firmakode',
    corporateCodeSuccessBody: '$corporateCode$ er blevet tilføjet.',
    corporateCodeButtonSubmit: 'Tilføj',
    corporateCodePlaceholder: 'Har du en firmakode? Tilføj her',
    sponsorship: 'Du betaler $yourAmount$, arbejdsgiver $employersAmount$',
    discountCodeError:
      'Rabatkoden du har indtastet findes ikke eller kan ikke anvendes til dette køb.',
    home: 'Hjem',
    chooseTemplate: 'Vælg $value$',
    readMoreTemplate: 'Læs mere om $topic$',
  },
  fi: {
    step: 'Vaihe $value$ / $max$',
    back: 'Takaisin',
    change: 'muuttaa',
    changeAddons: 'Muuttaa lisäpalvelusi',
    changeClub: 'Muuttaa pääkeskus',
    changeMembertype: 'Muuttaa mäsenyys',
    changeMembership: 'Muuttaa jäsenyys',
    choose: 'Choose',
    club: 'Pääkeskus',
    membership: 'Jäsenyys',
    memberType: 'Mäsenyys',
    generic: 'Jotain meni pieleen. Kokeile myöhemmin uudestaan.',
    missingQueryParams:
      'Jotain meni pieleen ja meidän täytyi navigoida takaisin.',
    expiredSession:
      'Istunto vanhentui, ja meidän piti ohjata sinut takaisin viimeiseen paikkaan, josta pystyimme aloittamaan uuden istunnon.',
    authenticationFailed:
      'Syötetyissä tiedoissa näyttää olevan jotain vikaa. Täytitkö kaikki kentät oikein?',
    checkoutSummary: {
      addons: 'Lisäpalvelusi',
      appliedCampaign: 'Aktiivinen kampanja',
      homeClub: 'Kotikeskus',
      memberType: 'Jäsenyystyppi',
      bindingPeriod: 'Sidonnaisuusaika',
      signUpFee: 'Liittymismaksu',
      bindingPeriodTotalPrice: 'Kokonaissumma sidonnaisuusajalla',
      price: 'Jäsenmaksu',
      standardPrice: 'Normaalihinta kampanjajakson jälkeen $standardPrice$',
    },
    paymentSummary: {
      signUpFee: 'Liittymismaksu',
      firstPaymentPeriod: 'Ensimmäinen maksujakso',
      total: 'Yhteensä',
    },
    logout: 'Kirjautua ulos',
    close: 'Sulje',
    next: 'Seuraava',
    open: 'Avaa',
    campaignCodeButtonSubmit: 'Käytä',
    campaignCode: 'Kampanjakoodi',
    campaignCodeSuccessBody: '$campaignCode$ on otettu käyttöön onnistuneesti.',
    campaignCodePlaceholder: 'Onko sinulla kampanjakoodi? Käytä se täällä',
    corporateCode: 'Yrityskoodi',
    corporateCodeSuccessBody:
      '$corporateCode$ on otettu käyttöön onnistuneesti.',
    corporateCodeError:
      'Lisäämääsi yrityskoodia ei voida yhdistää tekemiisi valintoihin, tai koodia ei ole olemassa.',
    corporateCodeButtonSubmit: 'Käytä',
    corporateCodePlaceholder: 'Onko sinulla yrityskoodi? Käytä se täällä',
    sponsorship: 'Sinä maksat $yourAmount$, ja työnantaja $employersAmount$',
    discountCodeError:
      'Syöttämääsi alennuskoodia ei voi käyttää valintojesi kanssa tai sitä ei ole olemassa.',
    home: 'Koti',
    chooseTemplate: 'Valitse $value$',
    readMoreTemplate: 'Lue lisää $topic$',
  },
  no: {
    step: 'Steg $value$ av $max$',
    back: 'Tilbake',
    change: 'Endre',
    changeAddons: 'Endre dine tilleggsvalg',
    changeClub: 'Endre hovedsenter',
    changeMembertype: 'Endre medlemstype',
    changeMembership: 'Endre medlemskap',
    choose: 'Velg',
    club: 'Hovedsenter',
    membership: 'Medlemskap',
    memberType: 'Medlemstype',
    generic: 'Noe gikk galt. Prøv igjen litt senere.',
    missingQueryParams: 'Noe gikk galt og vi måtte gå et steg tilbake.',
    expiredSession: 'Økten din har utløpt. Vennligst prøv igjen.',
    authenticationFailed:
      'Det ser ut til å være noe galt med de angitte dataene. Har du fylt ut alle feltene riktig?',
    checkoutSummary: {
      addons: 'Dine tilleggsvalg',
      appliedCampaign: 'Aktiv kampanje',
      homeClub: 'Hovedsenter',
      memberType: 'Medlemstype',
      bindingPeriod: 'Bindingstid',
      signUpFee: 'Innmeldingsavgift',
      bindingPeriodTotalPrice: 'Totalpris for bindingsperioden',
      price: 'Medlemskapsavgift',
      standardPrice: 'Normalpris etter kampanjeperioden $standardPrice$',
    },
    paymentSummary: {
      signUpFee: 'Innmeldingsavgift',
      firstPaymentPeriod: 'Første betalingsperiode',
      total: 'Totalt',
    },
    logout: 'Logg ut',
    close: 'Lukk',
    next: 'Neste',
    open: 'Åpne',
    campaignCodeButtonSubmit: 'Legg til',
    campaignCode: 'Kampanjekode',
    campaignCodeSuccessBody:
      '$campaignCode$ har blitt lagt til i din bestilling.',
    campaignCodePlaceholder: 'Har du en kampanjekode? Legg til her',
    corporateCode: 'Bedriftskode',
    corporateCodeSuccessBody:
      '$corporateCode$ har blitt lagt til i din bestilling.',
    corporateCodeButtonSubmit: 'Legg til',
    corporateCodePlaceholder: 'Har du en bedriftskode? Legg til her',
    sponsorship:
      'Du betaler $yourAmount$, og din arbeidsgiver blir fakturert $employersAmount$.',
    discountCodeError:
      'Rabattkoden du har lagt til kan ikke brukes med dine valg eller eksisterer ikke.',
    home: 'Hjem',
    chooseTemplate: 'Velg $value$',
    readMoreTemplate: 'Les mer om $topic$',
  },
  sv: {
    step: 'Steg $value$ av $max$',
    back: 'Tillbaka',
    change: 'Ändra',
    changeAddons: 'Ändra dina tillägg',
    changeClub: 'Ändra hemmagym',
    changeMembertype: 'Ändra medlemstyp',
    changeMembership: 'Ändra medlemskap',
    choose: 'Välj',
    club: 'Hemmagym',
    membership: 'Medlemskap',
    memberType: 'Medlemstyp',
    generic: 'Oops! Något gick fel. Vänligen försök igen senare.',
    missingQueryParams: 'Något gick fel och vi behövde gå tillbaka i flödet.',
    expiredSession:
      'Sessionstiden gick ut och vi behövde gå tillbaka till den senaste platsen vi kunde starta en ny session från.',
    authenticationFailed:
      'Det verkar vara något fel med de angivna uppgifterna. Fyllde du i alla fält korrekt?',
    checkoutSummary: {
      addons: 'Dina tillval',
      appliedCampaign: 'Aktiv kampanj',
      homeClub: 'Hemmagym',
      memberType: 'Medlemstyp',
      bindingPeriod: 'Bindningstid',
      signUpFee: 'Startavgift',
      bindingPeriodTotalPrice: 'Total pris',
      price: 'Medlemskapsavgift',
      standardPrice: 'Normalpriset efter kampanjperioden är $standardPrice$',
    },
    paymentSummary: {
      signUpFee: 'Startavgift',
      firstPaymentPeriod: 'Första betalningsperiod',
      total: 'Totalt',
    },
    logout: 'Logga ut',
    close: 'Stäng',
    next: 'Nästa',
    open: 'Öppna',
    campaignCodeButtonSubmit: 'Använd',
    campaignCode: 'Kampanjkod',
    campaignCodeSuccessBody: '$campaignCode$ har lagts till.',
    campaignCodePlaceholder: 'Har du en kampanjkod? Ange den här',
    corporateCode: 'Företagskod',
    corporateCodeSuccessBody: '$corporateCode$ har lagts till.',
    corporateCodeButtonSubmit: 'Använd',
    corporateCodePlaceholder: 'Har du en företagskod? Ange den här',
    sponsorship:
      'Du betalar $yourAmount$, din arbetsgivare faktureras $employersAmount$.',
    discountCodeError:
      'Den rabattkod du har lagt till finns inte eller kan inte användas för det här köpet.',
    home: 'Hem',
    chooseTemplate: 'Välj $value$',
    readMoreTemplate: 'Läs mer om $topic$',
  },
};
