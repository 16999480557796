import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import type { CompletedSteps as Props } from './completed-steps.props';

const CompletedSteps: React.FunctionComponent<Props> = ({ steps }) => (
  <div className="completed-steps">
    {steps.map(({ step, title, product, change }) => (
      <div className="completed-steps__step" key={title}>
        <div className="completed-steps__details">
          <Text theme={Text.themes.emphasis}>{`${step}.`}</Text>
          <Text theme={Text.themes.emphasis}>{title}</Text>
        </div>
        <div className="completed-steps__details">
          <Text>{product}</Text>
          <LinkButton variant={LinkButton.variants.secondary} {...change} />
        </div>
      </div>
    ))}
  </div>
);

export default CompletedSteps;
