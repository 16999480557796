import Check from '@sats-group/icons/16/check';
import ArrowRight from '@sats-group/icons/24/arrow-right';
import React from 'react';

import Tag from 'sats-ui-lib/react/tag';
import Text from 'sats-ui-lib/react/text';

import { Club as Props } from './club.props';

const Club: React.FunctionComponent<Props> = ({
  benefits,
  id,
  link,
  name,
  region,
}) => (
  <a className="club" href={link} data-id={id}>
    <div className="club__left">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
        {name}
      </Text>
      {region ? (
        <div className="club__tag">
          <Tag text={region} />
        </div>
      ) : null}
    </div>
    {benefits ? (
      <div className="club__benefits">
        {benefits.map(benefit => (
          <div className="club__benefit" key={benefit}>
            <Text size={Text.sizes.small}>
              <Check />
            </Text>
            <Text size={Text.sizes.small}>{benefit}</Text>
          </div>
        ))}
      </div>
    ) : null}
    <div className="club__icon">
      <ArrowRight />
    </div>
  </a>
);

export default Club;
