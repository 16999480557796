import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { FunctionComponent } from 'react';

import { renderOptions } from './rich-text-renderer-options';
import type { RichText as Props } from './rich-text.types';

const RichText: FunctionComponent<Props> = ({ content }) => (
  <div className="rich-text">
    {content ? documentToReactComponents(content, renderOptions) : null}
  </div>
);

export default RichText;
