import React from 'react';

import Club from '../club/club';

import { Cluster as Props } from './cluster.props';

const Cluster: React.FC<Props> = ({ clubs }) => (
  <div className="cluster">
    {clubs.map(club => (
      <Club {...club} key={club.id} />
    ))}
  </div>
);

export default Cluster;
