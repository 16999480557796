import Check from '@sats-group/icons/16/check';
import Close from '@sats-group/icons/16/close';
import SvgArrowDown from '@sats-group/icons/24/arrow-down';
import SvgArrowUp from '@sats-group/icons/24/arrow-up';
import cn from 'classnames';
import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Button from 'sats-ui-lib/react/button/button';
import Flag from 'sats-ui-lib/react/flag';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import { MembershipCard as Props } from './membership-card.props';

const MembershipCard: React.FunctionComponent<Props> = ({
  adjustedPrice,
  benefits,
  close,
  description,
  drawbacks,
  flag,
  highlighted,
  id,
  link,
  name,
  open,
  price,
  systemId,
}) => {
  const [isOpen, setIsOpen] = useState(highlighted);

  return (
    <div
      className={cn('membership-card', {
        'membership-card--highlighted': highlighted,
      })}
      data-id={id}
      data-system-id={systemId}
    >
      {flag ? (
        <div className="membership-card__flag">
          <Flag
            theme={Flag.themes.block}
            variant={Flag.variants.primary}
            {...flag}
          />
        </div>
      ) : null}
      <div className="membership-card__descriptions">
        <div className="membership-card__descriptions-top">
          <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
            {name}
          </Text>
          <div className="membership-card__trigger">
            <Button
              ariaLabel={isOpen ? close : open}
              aria-expanded={isOpen}
              icon={isOpen ? <SvgArrowUp /> : <SvgArrowDown />}
              onClick={() => setIsOpen(isOpen => !isOpen)}
              size={Button.sizes.small}
              variant={Button.variants.secondary}
            />
          </div>
        </div>
        {description ? (
          <Text
            className={cn('membership-card__description', {
              'membership-card__description--open': isOpen,
            })}
          >
            {description}
          </Text>
        ) : null}
      </div>

      <div
        className={cn('membership-card__middle', {
          'membership-card__middle--open': isOpen,
        })}
      >
        <div className="membership-card__price-wrapper">
          <div
            className={cn('membership-card__price', {
              'membership-card__price--reduced': adjustedPrice,
            })}
          >
            <Text
              size={adjustedPrice ? Text.sizes.small : Text.sizes.large}
              theme={adjustedPrice ? Text.themes.normal : Text.themes.emphasis}
            >
              {price}
            </Text>
          </div>
          {adjustedPrice ? (
            <div className="membership-card__adjusted-price">
              <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
                {adjustedPrice}
              </Text>
            </div>
          ) : null}
        </div>
        {benefits || drawbacks ? (
          <Collapse isOpen={isOpen} className="membership-card__collapse">
            <div className="membership-card__products">
              {benefits ? (
                <div className="membership-card__products-list">
                  {benefits.map(product => (
                    <div key={product} className="membership-card__product">
                      <Text
                        size={Text.sizes.small}
                        theme={Text.themes.emphasis}
                      >
                        <Check />
                      </Text>
                      <Text size={Text.sizes.small}>{product}</Text>
                    </div>
                  ))}
                </div>
              ) : null}
              {drawbacks ? (
                <div className="membership-card__products-list">
                  {drawbacks.map(product => (
                    <div key={product} className="membership-card__product">
                      <Text
                        size={Text.sizes.small}
                        theme={Text.themes.emphasis}
                      >
                        <Close />
                      </Text>
                      <Text size={Text.sizes.small}>{product}</Text>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </Collapse>
        ) : null}
      </div>

      <div className="membership-card__bottom">
        <LinkButton
          {...link}
          variant={highlighted ? 'primary' : 'secondary'}
          wide={true}
          data-test-select-membership
        />
      </div>
    </div>
  );
};

export default MembershipCard;
