import { nanoid } from 'nanoid';

import { Language } from 'utils/resolve-language';

const postalCodes = {
  no: '0558',
  sv: '11120',
  da: '2300',
  fi: '00100',
};

export const fillRegistrationForm = (language?: Language) => {
  const id = nanoid();

  const formData: (
    | { name: string; fieldName: 'value'; value: string }
    | { name: string; fieldName: 'checked'; value: boolean }
  )[] = [
    {
      name: 'newOtherPayerFirstName',
      fieldName: 'value',
      value: `xxx--payer-first-${id}`,
    },
    {
      name: 'newOtherPayerLastName',
      fieldName: 'value',
      value: `xxx--payer-last-${id}`,
    },
    {
      name: 'newOtherPayerBirthDay',
      fieldName: 'value',
      value: `${(Math.floor(Math.random() * 28) + 1).toString().padStart(2, '0')}-${(Math.floor(Math.random() * 12) + 1).toString().padStart(2, '0')}-${
        new Date().getFullYear() - Math.floor(Math.random() * 70) - 18
      }`,
    },
    {
      name: 'newOtherPayerSocialSecurityNumber',
      fieldName: 'value',
      value: '811218-9876',
    },
    {
      name: 'newOtherPayerGender',
      fieldName: 'value',
      value: Math.random() > 0.5 ? 'Male' : 'Female',
    },
    {
      name: 'newOtherPayerEmail',
      fieldName: 'value',
      value: `xxx-test-payer-${id}@example.com`,
    },
    {
      name: 'newOtherPayerAddress',
      fieldName: 'value',
      value: `xxx-${id}`,
    },
    {
      name: 'newOtherPayerPostalCode',
      fieldName: 'value',
      value: postalCodes[language ?? 'no'],
    },
    {
      name: 'newOtherPayerPhone',
      fieldName: 'value',
      value: `${
        Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
      }`,
    },

    {
      name: 'memberFirstName',
      fieldName: 'value',
      value: `xxx-first-${id}`,
    },
    {
      name: 'memberLastName',
      fieldName: 'value',
      value: `xxx-last-${id}`,
    },
    {
      name: 'memberBirthDay',
      fieldName: 'value',
      value: `${(Math.floor(Math.random() * 28) + 1).toString().padStart(2, '0')}-${(Math.floor(Math.random() * 12) + 1).toString().padStart(2, '0')}-${
        new Date().getFullYear() - Math.floor(Math.random() * 70) - 18
      }`,
    },
    {
      name: 'memberSocialSecurityNumber',
      fieldName: 'value',
      value: `991122-2389`,
    },
    {
      name: 'memberGender',
      fieldName: 'value',
      value: Math.random() > 0.5 ? 'Male' : 'Female',
    },
    {
      name: 'memberEmail',
      fieldName: 'value',
      value: `xxx-test-${id}@example.com`,
    },
    {
      name: 'memberAddress',
      fieldName: 'value',
      value: `xxx-${id}`,
    },
    {
      name: 'memberPostalCode',
      fieldName: 'value',
      value: postalCodes[language ?? 'no'],
    },
    {
      name: 'memberPhone',
      fieldName: 'value',
      value: `${
        Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
      }`,
    },
    {
      name: 'termsAndConditions',
      fieldName: 'checked',
      value: true,
    },
  ];

  formData.forEach(({ name, fieldName, value }) => {
    const element = document.querySelector<HTMLInputElement>(
      `input[name=${name}]`
    );

    if (element) {
      switch (fieldName) {
        case 'checked': {
          element.checked = value;
          break;
        }
        case 'value': {
          element.value = value;
          break;
        }
      }
    }

    const selectElement = document.querySelector<HTMLSelectElement>(
      `select[name=${name}]`
    );

    if (selectElement) {
      if (fieldName === 'value') {
        selectElement.value = value;
      }
    }
  });
};
