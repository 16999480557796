import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import SvgElixia from 'sats-ui-lib/react/logos/elixia';
import Sats from 'sats-ui-lib/react/logos/sats';
import Text from 'sats-ui-lib/react/text/text';

import { PaymentStatus as Props } from './payment-status.props';
const PaymentStatus: React.FunctionComponent<Props> = ({
  language,
  link,
  status,
}) => {
  return (
    <div className="payment-status">
      <div className="payment-status__logo">
        {language === 'fi' ? <SvgElixia /> : <Sats />}
      </div>
      <div className="payment-status__content">
        <Text elementName="h1">{status}</Text>
        {link ? <LinkButton {...link} /> : null}
      </div>
    </div>
  );
};

export default PaymentStatus;
