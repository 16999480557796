import cn from 'classnames';
import React from 'react';

import SvgElixia from 'sats-ui-lib/react/logos/elixia-small';
import Sats from 'sats-ui-lib/react/logos/sats-small';

import { Logo as Props } from './logo.props';

const Logo: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  language,
  className,
  href,
  label,
}) => (
  <a href={href} aria-label={label} className={cn(className)}>
    {language === 'fi' ? <SvgElixia /> : <Sats />}
  </a>
);

export default Logo;
